import { useTranslation } from "app";
import { useStores } from "hooks";
import React from "react";
import { ReactSVG } from "react-svg";
import { styles } from "./WhereIsVinModalContent.style";

const WhereIsVinModalContent: React.FC = () => {
  const { t } = useTranslation(["page-index"]);
  const { browserStore } = useStores();
  const modalImageName = browserStore.isTabletAndLarger ? "where-is-my-vin-desktop.svg" : "where-is-my-vin-mobile.svg";

  return (
    <>
      <div css={styles.modalTextWrapper()}>
        <h1 css={styles.modalHeading()}>{t("whereIsVinModal.title")}</h1>
        <p>
          <strong>{t("whereIsVinModal.paragraph1Title")}</strong>
          <br /> {t("whereIsVinModal.paragraph1")}
        </p>
        <p>
          <strong>{t("whereIsVinModal.paragraph2Title")}</strong>
          <br /> {t("whereIsVinModal.paragraph2")}
        </p>
      </div>
      <ReactSVG src={`/static/images/${modalImageName}`} />
    </>
  );
};

export default WhereIsVinModalContent;
