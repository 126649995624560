import React, { ReactNode, useState } from "react";
import dynamic from "next/dynamic";

import { useStores } from "hooks";
import { ButtonEventCategory, dataLayerEvents, PageTypes } from "domain/SeoAndAnalytics/GoogleAnalytics/utils";
import { routesUtil } from "domain/Routing/utils/routesUtil";
import { useRouter } from "next/router";
import { Image } from "contentful/types/content-type";
import { styles } from "./SampleReport.style";

const MobileReport = dynamic(() => import("./components/MobileReport/MobileReport"), {
  ssr: false,
});

const DesktopReport = dynamic(() => import("./components/DesktopReport/DesktopReport"), {
  ssr: false,
});
interface Props {
  children: ReactNode;
  optionalDesktopReport?: Image;
  optionalMobileReport?: Image;
}

const SampleReport: React.FC<Props> = ({ children, optionalDesktopReport, optionalMobileReport }) => {
  const { browserStore } = useStores();
  const { sampleReportStore } = useStores();
  const router = useRouter();
  const [sampleReportModalOpen, setSampleReportModalOpen] = useState(false);
  const [sampleReportMobileOpen, setSampleReportMobileOpen] = useState(false);

  const onClickSampleReportLink = (event: any) => {
    event.preventDefault();
    if (browserStore.isTabletAndLarger) {
      setSampleReportModalOpen(true);
    } else if (!browserStore.isTabletAndLarger) {
      setSampleReportMobileOpen(!sampleReportMobileOpen);
      sampleReportStore.toggleSampleReportMobileOpen();
    }

    if (routesUtil.isHomepage(router.asPath)) {
      const clickText = event.target.innerText;
      dataLayerEvents.submitGenericButtonClickEvent(PageTypes.HOMEPAGE, clickText, ButtonEventCategory.BUTTON);
    }
  };

  return (
    <div>
      <div
        data-testid="sample-report-open"
        onClick={(event) => onClickSampleReportLink(event)}
        css={styles.buttonWrapper()}
      >
        <>{children}</>
      </div>
      {sampleReportMobileOpen && (
        <MobileReport
          optionalMobileReport={optionalMobileReport}
          defaultSampleReports={sampleReportStore.defaultSampleReport}
        />
      )}
      {sampleReportModalOpen && (
        <DesktopReport
          sampleReportModalOpen={sampleReportModalOpen}
          setSampleReportModalOpen={setSampleReportModalOpen}
          optionalDesktopReport={optionalDesktopReport}
          defaultSampleReports={sampleReportStore.defaultSampleReport}
        />
      )}
    </div>
  );
};

export default SampleReport;
